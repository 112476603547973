import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore"
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';

// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_apiKey,
//     authDomain: process.env.REACT_APP_authDomain,
//     projectId: process.env.REACT_APP_projectId,
//     storageBucket: process.env.REACT_APP_storageBucket,
//     messagingSenderId: process.env.REACT_APP_messagingSenderId,
//     appId: process.env.REACT_APP_appId
// };
//const firebaseConfig = {
//    apiKey: "AIzaSyAt91uCnvao_tix2doVRc_c8lGPKE6xU7o",
//    authDomain: "food-blog-23491.firebaseapp.com",
//    projectId: "food-blog-23491",
//    storageBucket: "food-blog-23491.appspot.com",
//    messagingSenderId: "159200101528",
//    appId: "1:159200101528:web:74c27bff29394c1d473ced"
//};

const firebaseConfig = {
    apiKey: "AIzaSyDefbra-qTI_HF8yBftMWAZcffDdfL7g9k",
    authDomain: "lw3system-3f8d4.firebaseapp.com",
    projectId: "lw3system-3f8d4",
    storageBucket: "lw3system-3f8d4.appspot.com",
    messagingSenderId: "464052913172",
    appId: "1:464052913172:web:2a8f9cce9db928a4a15301",
    measurementId: "G-8RF144EVWK"
  };

export const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const functions = getFunctions(app);
// Use emulator
connectFunctionsEmulator(functions, "localhost", 5001);
export const callableFunction = (name) => httpsCallable(functions, name)
