import { toast, Toaster } from 'react-hot-toast';
import React, { useState } from 'react'
import { getAuth, sendPasswordResetEmail } from "firebase/auth";


export default function ForgotPassword() {
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')

    const getOTP = async (e) => {
        e.preventDefault()
        const auth = getAuth();
        setLoading(true)
        try {
            await sendPasswordResetEmail(auth, email)
            console.log('Reset link has been sent to your email')
            toast.success('Password reset link has been sent to your email')
        } catch (error) {
            if (error.code === 'auth/user-not-found') {
                toast.error('Invalid email')
                console.log('User not found')
            }
        } finally {
            setLoading(false)
        }
    }

    return (
        <main className='my-6'>
            <Toaster />
            <center>
                <h1 className='font-semibold'>Reset Your Password</h1>
            </center>

            <form action="" className='shadow-lg p-4 w-[450px] mx-auto'>
                <div className="mb-4">
                    <label className="label" htmlFor="username">
                        Email
                    </label>
                    <input className="input" name="email" type="email" placeholder="user@123.com" onChange={e => setEmail(e.target.value)} />
                </div>

                <button disabled={loading} className='btn text-white bg-green-500 hover:bg-green-400 mr-4'
                    onClick={getOTP}>Get OTP</button>
            </form>
        </main>
    )
}

