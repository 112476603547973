import React, { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import { Link } from "react-router-dom";
import { collection, getDocs, orderBy, query } from "firebase/firestore"; 
import { firestore } from "../../firebase";
import toast from "react-hot-toast";
import { callableFunction } from './../../firebase/index';
import {v4 as uuidv4} from 'uuid'

import { getAuth, onAuthStateChanged } from "firebase/auth";

const auth = getAuth()

export default () => {
    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(false)
    const [brandId, setBrandId] = useState(null)
    const [product, setProduct] = useState( null )
    const [batchId, setBatchId] = useState( '' )

    function validate() {
        console.log('Produvt id ', product)
        if(!product){
            return {isValid: false, message: 'Select product'}
        }
        if(!batchId) {
            return {isValid: false, message: 'Please enter Lot / Batch number'}
        }
        return {
            isValid: true
        }
    }


    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if(user){
                setBrandId(user.uid)
            }
        })
    }, [])


    useEffect(() => {
        setLoading(true)
        getDocs(query(collection(firestore, "product_abstracts"), orderBy('data.name')))
        .then(snapshot => {
            console.log(snapshot.docs.map(doc => { return {...doc.data(), id: doc.id} }))
            setProducts(snapshot.docs.map(doc => { return {...doc.data(), id: doc.id} }))
        })
        .catch(err => {

        })
        .finally(() => {
            setLoading(false)
        })
    }, [])

    async function generateQRCode(event) {
        event.preventDefault()
        let validation = validate()
        if(validation.isValid){
            setLoading(true)
         
            let chipId = '' // uuidv4()

            callableFunction("productGenesisBlock")({chipId, productAbstractId: product.id, name: product.data.name, brandId, batchId: batchId})
            .then(result => {
                let data = result.data
                if(!data.data.error){
                    toast.success("Product saved successfully")
                    setTimeout(() => {
                        window.location.href = "/qr-codes"
                    }, 1000);
                } else {
                    toast.error(data.data.error)
                }
                //console.log(data)
            })
            .catch(error => {
                const code = error.code;
                const message = error.message;
                const details = error.details;
                console.log(code, message, details)
                toast.error("Failed: "+error.message)
            }).finally(() => {
                setLoading(false)
            })

            //setLoading(false)
        } else {
            toast.error(validation.message)
        }
    }

    function selectProduct(event) {
        setProduct(products.find(v => v.id == event.target.value))
    }

    function changeBatch(e){
        setBatchId(e.target.value)
    }

    return (<div className="m-4">
        <PageHeader>
            <>
                <h4 className="text-lg">Create new batch QR code</h4>
                <Link to={"/qr-codes"}><button className="btn bg-blue-500">Back</button></Link>
            </>
        </PageHeader>
        <div>
            <form onSubmit={generateQRCode}>

                <div className="bg-blue-200 p-2">
                    <label>Product*</label>
                    <select disabled={loading} onChange={selectProduct}>
                        <option value={""}>Select</option>
                        {products.map(v => (
                            <option key={v.id} value={v.id}>{v.data.name} {Boolean(v.id)?'': '(Disabled)'}</option>
                        ))}
                    </select>
                </div>
                
                <div className="p-2">
                    <label>Batch / Lot No.</label>
                    <input className="" type="text" onChange={(e) => changeBatch(e)}/>
                </div>

                <div className="mt-4 bg-white" style={{position: 'sticky', bottom: 0, paddingBottom: 10, paddingTop: 10 }}>
                    <button disabled={loading} className="btn bg-red-400" onClick={generateQRCode}>{loading?"Generating ...":'Generate QR Code'}</button>
                </div>
                
            </form>
        </div>
    </div>)
}