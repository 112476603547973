import React, { useEffect, useState } from "react"
import logo from './../images/logo.png'

const AppLinks = () => {
   
    return (<div style={{textAlign: 'center'}}>
        <img src={logo} width={100} style={{margin: '0 auto'}}/>

        <div className="mt-4 flex justify-center">
            <AppDownlod />
        </div>
    </div>)
}



const AppDownlod = () => {
    return (
        <>
			<div className="p-2 w-full text-center rounded-lg border shadow-md sm:p-8 bg-gray-800 border-gray-700 mb-6" style={{maxWidth: 600}}>
			<h3 className="mb-2 text-3xl font-bold text-white mb-4">Authenticate your product and know more..</h3>
			{/*<p className="mb-5 text-md text-gray-500 sm:text-lg text-gray-400">Dive into the best option trading course available in the market</p>*/}
			<div className="justify-center items-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4 mb-2">
				<a href="https://bit.ly/3Pxz4X6" className="w-full sm:w-auto flex bg-gray-600 hover:bg-gray-500 focus:ring-4 focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 bg-gray-700 hover:bg-gray-600 focus:ring-gray-700">
					<svg className="mr-3 w-7 h-7" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google-play"
						role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
						<path fill="currentColor"
							d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z">
						</path>
					</svg>
					<div className="text-left">
						<div className="mb-1 text-xs">Get it on</div>
						<div className="-mt-1 font-sans text-sm font-semibold">Google Play</div>
						{/* <span className="md:hidden ml-2 bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">Coming soon</span> */}
					</div>
				</a>
				<a href="https://apple.co/3PPB3ax"
					className="w-full sm:w-auto flex bg-gray-600 hover:bg-gray-500 focus:ring-4 focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 bg-gray-700 hover:bg-gray-600 focus:ring-gray-700">
					<svg className="mr-3 w-7 h-7" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="apple"
						role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
						<path fill="currentColor"
							d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z">
						</path>
					</svg>
					<div className="text-left">
						<div className="mb-1 text-xs">Download on the</div>
						<div className="-mt-1 font-sans text-sm font-semibold">App Store</div>
						<div className="md:inline-flex justify-end hidden" style={{position: "absolute", margin: "20px 20px 10px -18px"}}>
							{/*<span className=" ml-2 bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">Coming soon</span>*/}
						</div>
					</div>
				</a>
			</div>
			</div>
		</>
    )
}

export default AppLinks