import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import PageHeader from "../components/PageHeader"
import { firestore } from "../firebase"
import { collection, getCountFromServer, query, where } from "firebase/firestore"
import { getAuth } from "firebase/auth"


const DashBoardContent = () => {
  const auth = getAuth();
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState({
    products: 0,
    qrcodes: 0
  })
  useEffect(() => {
    try {
      countProducts()
    } catch (error) {
      
    }
  }, [])

  async function countProducts() {
    const coll = collection(firestore, "product_abstracts");
    const mquery = query(coll, where( "uid", "==", auth.currentUser.uid ))
    //const productCount = await getCountFromServer(mquery);
    //const productCount2 = await getCountFromServer(mquery2);

    const coll2 = collection(firestore, "products");
    const mquery2 = query(coll2, where( "uid", "==", auth.currentUser.uid ))

    setLoading(true)
    Promise.all([getCountFromServer(mquery), getCountFromServer(mquery2)])
    .then(resp => {
      console.log(resp)
      setCount({
        products: resp[0].data().count,
        qrcodes: resp[1].data().count
      })
    })
    .catch((err) => {

    })
    .finally(() => {
      setLoading(false)
    })
  }

  const LoadingComp = () => {
    return <p>Loading data...</p>
  }

    return (
      <div className="m-4">
        <PageHeader>
          <>
            <h4 className="text-lg">Dashboard</h4>
          </>
        </PageHeader>
        <div className="flex">
          {loading ? <LoadingComp></LoadingComp> : <>
          <Link className='border-gray-200 hover:bg-blue-200 ml-2 border-2 shadow-lg p-5' to={"/products"}>
            <h1 className="text-lg text-center">{count.products}</h1>
            <h1>Products</h1>
          </Link>
          
          <Link className='border-gray-200 hover:bg-blue-200 ml-2 border-2 shadow-lg p-5' to={"/qr-codes"}>
            <h1 className="text-lg text-center">{count.qrcodes}</h1>
            <h1>QR Codes</h1>
          </Link>
          </>}
          
        </div>
      </div>
    )
  }

export default DashBoardContent