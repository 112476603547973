import React, { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import { Link, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { callableFunction } from './../../firebase/index'
import { firestore as db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";


export default () => {
    const [loading, setLoading] = useState(false)
    const [onBlockchain, setOnBlockchain] = useState(true)
    const [name, setName] = useState( '' )
    const [ifields, setIfields] = useState([ 0, 0, 0, 0, 0, 0, 0, 0, 0 ])
    const [sfields, setSfields] = useState([ '', '', '', '', '', '', '', '', '', '' ])

    const location = useLocation()

    useEffect(() => {
        let params = new URLSearchParams(location.search)
        let pid = params.get('pid')

        if(pid) {
            getDoc(doc(db, "product_abstracts", pid)).then(resp => {
                let obj = resp.data()
                console.log(obj)
                setName(obj.data.name)
                setIfields(obj.data.ifields)
                setSfields(obj.data.sfields)
            })
            .catch(err => {
                console.log(err)
            })
        }
        //console.log(params.get('pid'))
    }, [])

    function validate() {
        if(name.trim().length < 1){
            return {isValid: false, message: 'Name is required'}
        }
        return {
            isValid: true
        }
    }

    async function addProduct(event) {
        event.preventDefault()
        let validation = validate()
        if(validation.isValid){
            setLoading(true)
            console.log(name, ifields, sfields)
             
            callableFunction("addProductAbstract")({
                name,
                ifields,
                sfields,
                onBlockchain
            }).then(result => {
                let data = result.data
                if(data.data.success){
                    toast.success("Product saved successfully")
                    setTimeout(() => {
                        window.location.href = "/products"
                    }, 1000);
                } else {
                    toast.error(data.data.message)
                }
                //console.log(data)
            })
            .catch(error => {
                const code = error.code;
                const message = error.message;
                const details = error.details;
                console.log(code, message, details)
                toast.error("Failed: "+error.message)
            }).finally(() => {
                setLoading(false)
            })

            //setLoading(false)
        } else {
            toast.error(validation.message)
        }
    }

    return (<div className="m-4">
        <PageHeader>
            <>
                <h4 className="text-lg">Create New Product</h4>
                <Link to={"/products"}><button className="btn bg-blue-500">Back</button></Link>
            </>
        </PageHeader>
        <div>
            <form onSubmit={addProduct}>
                
                <div>
                    <label>Product Name*</label>
                    <input disabled={loading} type="text" placeholder="Minimum 3 characters" maxLength={100} defaultValue={name} onChange={(e) => setName(e.target.value)}/>
                </div>

                {[1,2,3,4,5,6,7,8,9,10].map(item => (
                    <div className="mt-2" key={item}>
                        <label>Text Field {item}</label>
                        <input disabled={loading} type="text" maxLength={100} onChange={(e) => {
                            let temp = sfields.concat([])
                            temp[item-1] = e.target.value
                            setSfields(temp)
                            return
                        }}
                        defaultValue={sfields[item-1]}
                        />
                    </div>
                ))}
                
                {[1,2,3,4,5,6,7,8,9,10].map(item => (
                    <div className="mt-2" key={item}>
                        <label>Number Field {item}</label>
                        <input disabled={loading} type="number" step="any" maxLength={100} placeholder="Defaul value is 0" onChange={(e) => {
                            let temp = ifields.concat([])
                            temp[item-1] = e.target.value?String(e.target.value):''
                            setIfields(temp)
                            return 
                        }}
                        defaultValue={ifields[item-1]}
                        />
                    </div>
                ))}

                <div className="mt-4 bg-white" style={{position: 'sticky', bottom: 0, paddingBottom: 10, paddingTop: 10 }}>
                    <button disabled={loading} className="btn bg-red-400" onClick={addProduct}>{loading?"Adding product...":'Add Product'}</button>
                </div>
                
            </form>
        </div>
    </div>)
}