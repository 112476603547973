import React, { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import { collection, getDocs, orderBy, query } from "firebase/firestore"; 
import { firestore } from "../../firebase";
import { Link } from "react-router-dom";

export default () => {
  const [loading, setLoading] = useState(false)
  const [products, setProducts] = useState([])

  useEffect(() => {
    setLoading(true)
    getDocs(query(collection(firestore, "productAbstracts")))
    .then(snapshot => {
      console.log(snapshot.docs.map(doc => {
        return {...doc.data(), id: doc.id}
      }))
      setProducts(snapshot.docs.map(doc => {
        return {...doc.data(), id: doc.id}
      }))
    })
    .catch(err => {

    })
    .finally(() => {
      setLoading(false)
    })
  }, [])

  function showData(prod){
    return alert(`ID: ${prod.id}\nName: ${prod.name}\nFields: \n${prod.fields.map(v => `${v.key} = ${v.value}`).join('')}`)
  }

  return (<div className="m-4">
    <PageHeader>
      <>
        <h4 className="text-lg">Product List</h4>
        <Link to={"/products/v2/new"}><button className="btn bg-blue-500">NEW</button></Link>
      </>
    </PageHeader>
    <table className="w-full border-collapse border border-slate-400">
      <thead className='text-left my-20'>
        <tr>
          <th className="p-2">SN</th>
          <th className="p-2">ID</th>
          <th className="p-2">Name</th>
          <th className="p-2">Transaction Hash</th>
          <th className="p-2">Timestamp</th>
          <th className="p-2">Action</th>
        </tr>
      </thead>
      <tbody>
        {!loading && products.length < 1 && <tr>
            <td colSpan="6" className="p-4 text-center">Product not found!</td>
          </tr>}
        {loading && <tr>
            <td colSpan="6" className="p-4 text-center">Loading please wait...</td>
          </tr>}
        {products.map((prod, proi) =>(<tr key={proi} className="py-4">
          <td className="py-4 px-2">{proi+1}</td>
          <td className="py-4 px-2">{prod.id}</td>
          <td className="py-4 px-2">{prod.name}</td>
          <td className="py-4 px-2">{prod.ipfsHash}</td>
          <td className="py-4 px-2">{new Date(prod.ts.seconds*1000).toDateString()}</td>
          <td className="py-4 px-2">
            {/*<a className="btn bg-red-500 mr-2" target="_blank" href={prod.transactionHash ? "https://polygonscan.com/tx/"+prod.transactionHash : '#'}>Transaction</a>*/}
            {/*to={`/products/v2/${prod.id}`}*/}
            <Link className="btn bg-blue-500 mr-2" onClick={() => showData(prod)}>View</Link>
            {/*<Link className="btn bg-yellow-500" to={`/products/new?pid=${prod.id}`}>Clone</Link>*/}
          </td>
        </tr>))}
      </tbody>
    </table>
  </div>)
}