import React, { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import { Link, useParams } from "react-router-dom";
import { firestore } from "../../firebase";
import { collection, doc, getDoc, getDocs, orderBy, query, setDoc } from "firebase/firestore"; 

export default () => {
    const [product, setProduct] = useState(null)
    const [loading, setLoading] = useState(false)

    let {id} = useParams()

    useEffect(() =>  {
        
        getDoc(query(doc(firestore, "product_abstracts", id), orderBy('data.name')))
        .then(snapshot => {
            setProduct({ ...snapshot.data(), id })
        })
        .catch(err => {

        })
        .finally(() => {
            setLoading(false)
        })

    }, [])

    return (<div className="m-4">
        <PageHeader>
            <>
              <h4 className="text-lg">Product View</h4>
              <Link to={"/products"}><button className="btn bg-blue-500">BACK</button></Link>
            </>
        </PageHeader>
        {loading && <p className="text-center">Loading data...</p>}
        {product &&(
            <>

                <div className="bg-blue-200 p-2">
                    <label>Product Name*</label>
                    <p>{product.data.name}</p>
                </div>

                {[1,2,3,4,5,6,7,8,9,10].map(item => (
                    <div className="mt-2 bg-gray-200 p-2" key={item}>
                        <label>Text Field {item}</label>
                        <p>{product.data.sfields[item] || 'NA'}</p>
                    </div>
                ))}
                
                {[1,2,3,4,5,6,7,8,9,10].map(item => (
                    <div className="mt-2 bg-red-200 p-2" key={item}>
                        <label>Number Field {item}</label>
                        <p>{product.data.ifields[item]}</p>
                    </div>
                ))}

            </>
        )

        }
    </div>)
}