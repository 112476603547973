import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import { Toaster } from 'react-hot-toast';
import { getAuth } from "firebase/auth";
import Signin from '../pages/Signin';
import Sidebar from './Sidebar';


export default ({ children }) => {
  const auth = getAuth();
  const [authState, setAuthState] = useState("loading")
  const [sidebar, toggleSidebar] = useState(true)

  useEffect(() => {
    auth.onAuthStateChanged(observer => {
      if(!observer) {
        setAuthState("unauthenticated")
      } else {
        setAuthState("authenticated")
      }
    })
    let slocal = localStorage.getItem("sidebar")
    if(slocal) toggleSidebar(Boolean(slocal))
  }, [])

  useEffect(() => {
    localStorage.setItem("sidebar", Boolean(sidebar))
  }, [sidebar])

  if(authState === 'loading') {
    return <div className='text-center'>
        Loading ...
    </div>
  }

  if(authState === 'unauthenticated') {
    return <Signin />
  }

  return (
    <div className="grid grid-cols-12 h-[100vh]">
      <Toaster />
      <Sidebar show={sidebar}/>
      <div className={sidebar?`col-span-10`:`col-span-12`}>
        <Navbar toggleSidebar={() => toggleSidebar(!sidebar)}/>
        <div> {children} </div>
        {/*<div className='col-span-12'></div>*/}
      </div>
      
    </div>
  )
}