import { Toaster, toast } from 'react-hot-toast';
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import logo from './../images/Logoblack.png'

import { getAuth, signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";

export default function Signin() {
    const auth = getAuth();
    const navigate = useNavigate()
    const [authError, setAuthError] = useState('')
    const [loading, setLoading] = useState(false)

    const [credential, setCredential] = useState({
        email: '',
        password: ''
    })

    const { email, password } = credential

    const handleCredential = (e) => {
        const { name, value } = e.target

        setCredential(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const signIn = async (e) => {
        e.preventDefault()
        try {
            setAuthError('')
            setLoading(true)
            await toast.promise(signInWithEmailAndPassword(auth, email, password), {
                loading: 'Loading...',
                success: <b>Logged In</b>,
                error: <b>Logged In Fail</b>,
            })
            console.log('User has been created')
            navigate('/dashboard')
        } catch (err) {
            console.log('There is no user')
            console.error(err.code);
            if (err.code === 'auth/user-not-found') {
                setAuthError('User not found')
            } else if (err.code === 'auth/wrong-password') {
                setAuthError('Wrong Password')
            }
            else {
                setAuthError(err.code)
            }
        } finally {
            setLoading(false)
        }
    };

    const createAccount = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            await createUserWithEmailAndPassword(auth, email, password);
            console.log('User has been created')
            navigate('/dashboard')
        } catch (err) {
            if (err.code === 'auth/email-already-in-use') {
                toast.error('Email already in use')
            }
            console.error(err);
        } finally{
            setLoading(true)
        }
    };

    const signInWithGoogle = async () => {
        try {
            setLoading(true)
            await toast.promise(signInWithPopup(auth, new GoogleAuthProvider()), {
                loading: 'Loading...',
                success: <b>Logged In</b>,
                error: <b>Logged In Fail</b>,
            })
            setLoading(false)
            navigate('/dashboard');
        } catch (err) {
            console.error(err);
            toast.error('Something went wrong. Try again')
        } finally{
            setLoading(true)
        }
    };

    // const logOut = async () => {
    //     try {
    //         await signOut(auth);
    //     } catch (err) {
    //         console.error(err);
    //     }
    // };

    return (
        <main className='my-6'>
            <Toaster />

            <center>
                <a href="/"><img src={logo} style={{maxWidth: 150, margin: '0 auto'}}  className="animate__animated animate__zoomInDown"/></a>
                {/*<h1 className='font-semibold mb-2 underline'>Please sign in to continue</h1>*/}
            </center>

            <GoogleSignin signInWithGoogle={signInWithGoogle} />

            <h1 className='text-center my-3'>OR</h1>

            <Form
                handleCredential={handleCredential}
                authError={authError}
                loading={loading}
                signIn={signIn}
                createAccount={createAccount} />

        </main>
    )
}

const GoogleSignin = ({ signInWithGoogle }) => {
    return (
        <div className='google-signin'>
            <img className='w-10 h-10 mr-1' src="https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-google-logos-vector-eps-cdr-svg-download-10.png" alt="Google Logo" />
            <button
                onClick={signInWithGoogle}
                className='hover:font-semibold'>Sign in with Google</button>
        </div>
    )
}

const Form = ({ handleCredential, authError, loading, signIn, createAccount }) => {
    return (
        <form action="" className='signin-form'>
            <div className="mb-4">
                <label className="label" htmlFor="username">
                    Email
                </label>
                <input className="input" name="email" type="email" placeholder="user@123.com" onChange={handleCredential} />
            </div>

            <div className="mb-4">
                <label className="label" htmlFor="username">
                    Password
                </label>
                <input className="input" name="password" type="password" placeholder="Password" onChange={handleCredential} />
            </div>

            {authError !== '' && <p className='text-red-500 mb-4 font-semibold italic'>{authError}</p>}

            <button disabled={loading} className='btn signin-btn' onClick={signIn}>Sign In</button>
            <button disabled={loading} className='btn create-account-btn' onClick={createAccount}>Create an account</button>

            <div className='my-4'>
                <Link to='/forgot-password' className='forgot-password'>Forgot password ?</Link>
            </div>

        </form>
    )
}