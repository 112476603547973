import React, { useState } from "react"
import { callableFunction } from './../../firebase/index'

export default () => {
    const [uid, setUid] = useState('')
    const [bid, setBid] = useState('')

    function saveData() {
        if(uid.length && bid.length){
            console.log(uid, bid)
            callableFunction('setBrandClaim')({
                uid, bid
            })
            .then((resp) => {
                console.log(resp)
            })
            .catch(err => {
                console.log(err)
            })
        }
    }
    return (<div>
        <h4>Make Member</h4>
        <div>
            <input type="text" placeholder="Enter Uid" onChange={(e) => setUid(e.target.value)}/>
            <input type="text" placeholder="Enter Business Id"  onChange={(e) => setBid(e.target.value)}/>
            <button onClick={saveData}>Save</button>
        </div>
    </div>)
}