import './App.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AdminLayout from './components/AdminLayout';
import DashBoardContent from './pages/Dashboard';
import ForgotPassword from './pages/ForgotPassword';
import Signin from './pages/Signin';
import Home from './pages/Home';
import Account from './pages/Account';
import SKU from './pages/skus/SKU';
import NewSKU from './pages/skus/NewSKU';
import ProductList from './pages/products/ProductList';
import NewProduct from './pages/products/NewProduct';
import ProductView from './pages/products/ProductView';

import ProductListV2 from './pages/products-v2/ProductList';
import NewProductV2 from './pages/products-v2/NewProduct';
import ProductViewV2 from './pages/products-v2/ProductView';

import QRCodeList from './pages/qr-codes/QRCodeList';
import NewQRCode from './pages/qr-codes/NewQRCode';
import ViewQRCode from './pages/qr-codes/ViewQRCode';
import AppLinks from './pages/AppLinks';
import Brand from './pages/brand/Brand';
import Member from './pages/member/Member';

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Home />
    ),
  },
  {
    path: "/apps",
    element: (
      <AppLinks />
    ),
  },
  {
    path: "/auth",
    element: (
      <Signin />
    ),
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />
  },
  {
    path: "/dashboard",
    element: <AdminLayout><DashBoardContent /></AdminLayout>
  },
  {
    path: "/qr-codes",
    element: <AdminLayout><QRCodeList /></AdminLayout>,
  },
  {
    path: "/qr-codes/id",
    element: <AdminLayout><ViewQRCode /></AdminLayout>,
  },
  {
    path: "/qr-codes/new",
    element: <AdminLayout><NewQRCode /></AdminLayout>,
  },
  {
    path: "/account",
    element: <AdminLayout><Account /></AdminLayout>,
  },
  {
    path: "/skus",
    element: <AdminLayout><SKU /></AdminLayout>,
  },
  {
    path: "/skus/new",
    element: <AdminLayout><NewSKU /></AdminLayout>,
  },
  {
    path: "/products/v2",
    element: <AdminLayout><ProductListV2 /></AdminLayout>,
  },
  {
    path: "/products/v2/new",
    element: <AdminLayout><NewProductV2 /></AdminLayout>,
  },
  {
    path: "/products/v2/:id",
    element: <AdminLayout><ProductViewV2 /></AdminLayout>,
  },
  
  {
    path: "/products",
    element: <AdminLayout><ProductList /></AdminLayout>,
  },
  {
    path: "/products/new",
    element: <AdminLayout><NewProduct /></AdminLayout>,
  },
  {
    path: "/products/:id",
    element: <AdminLayout><ProductView /></AdminLayout>,
  },


  {
    path: "/members",
    element: <AdminLayout><Member /></AdminLayout>,
  },

  
  {
    path: "/brand",
    element: <AdminLayout><Brand /></AdminLayout>,
  },
  {
    path: "/brand/edit",
    element: <AdminLayout><Brand isEdit={true}/></AdminLayout>,
  },
  //{
  //  path: "/*",
  //  element: <App />,
  //}
], {
  
});

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
