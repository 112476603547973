import React from "react"
import { Link, useNavigate } from "react-router-dom"
import { BiBell, BiMenu, BiUser } from "react-icons/bi";
import { toast } from "react-hot-toast";

const Navbar = ({toggleSidebar}) => {
    const navigate = useNavigate()
    //const hideMenu = () => {
    //  //navigate('/')
    //  toast.success("Hide Menu")
    //}

    function showNotification() {
      toast.success("Feature coming soon...")
    }
  
    return (
      <div className='nav items-center shadow-md flex flex-wrap justify-between'>
        <BiMenu onClick={toggleSidebar} />
        <div className="flex">
          <BiBell onClick={showNotification}/>
          <Link to={"/account"}><BiUser/></Link>
        </div>
        
      </div>
    )
  }

export default Navbar