import React from "react";
import PageHeader from "../../components/PageHeader";
import { Link } from "react-router-dom";


export default () => {
    return (<div className="m-4">
        <PageHeader>
            <>
            <h4 className="text-lg">SKU List</h4>
            <Link to={"/skus/new"}><button className="btn bg-blue-500">NEW</button></Link>
            </>
        </PageHeader>
        <table className="w-full border-collapse border border-slate-400">
            <thead className='text-left my-20'>
              <tr>
                <th>SN</th>
                <th>Name</th>
                <th>ID</th>
                <th>Timestamp</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
  
              </tr>
              <tr>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
              </tr>
              <tr>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
              </tr>
            </tbody>
          </table>
    </div>)
}