import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import logo from './../images/Logoblack.png'

const Sidebar = ({show = true}) => {
  const location = useLocation()

    return (
      <div className={'sidebar col-span-2 border-r-2 '+(!show?'hidden':'')}>
        <div className='nav px-4 py-2 shadow-md items-center flex flex-wrap'>
          {/*<h1 className='font-bold'>LW3 Admin</h1>*/}
          <div className="image-container" style={{
            width: 120,
            height: 40,
            overflow: 'hidden',
            position: 'relative'
          }}>
            <img src={logo} 
            style={{
              width: '100%',
              height: '100%' ,
              margin: '0 auto',
              objectFit: 'cover', 
              clip: 'rect(0px, 300px, 200px, 0px)'
            }}  className="animate__animated animate__bounceInLeft"/>
          </div>
          
        </div>
        <div className='sidebar-btns'>
          <Link to={"/dashboard"}><h1 className={`${location.pathname === '/dashboard' ? 'active-btn ' : ''}`}>Dashboard</h1></Link>
          <Link to={"/qr-codes"}><h1 className={`${location.pathname === '/qr-codes'  ? 'active-btn ' : ''}`}>QR CODE</h1></Link>
          {/*<Link to={"/skus"}><h1 className={`${location.pathname === '/skus'  ? 'active-btn ' : ''}`}>SKUs</h1></Link>*/}
          <Link to={"/products/v2"}><h1 className={`${location.pathname === '/products/v2'  ? 'active-btn ' : ''}`}>Products</h1></Link>
          <Link to={"/brand"}><h1 className={`${location.pathname === '/brand'  ? 'active-btn ' : ''}`}>Brand</h1></Link>
        </div>
      </div>
    )
  }
  export default Sidebar