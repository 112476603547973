import React, { useEffect, useState } from "react"
import { getAuth } from "firebase/auth";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Home = () => {
    const [user, setUser] = useState(null)
    const auth = getAuth();
    const navigate = useNavigate()


    useEffect(() => {
        if(auth.currentUser) setUser(auth.currentUser)
        auth.onAuthStateChanged((user) => {
            setUser(auth.currentUser)
        })
    }, [auth])

    function logout() {
        if(window.confirm("Logout current account?")){
            //auth.signOut().then(() => {
            //    toast.success('Succesfully logged in', {
            //        duration: 1500,
            //        position: 'top-center',
            //    })
            //    setTimeout(() => {
            //        navigate("/auth")
            //    }, 1500);
            //})

            toast.promise(auth.signOut(), {
                loading: 'Loading...',
                success: <b>Logged out sucessfully</b>,
                error: <b>Logged out Failed</b>,
            })
        }
    }

    return (
        <div className='content justify-center'>
            <div className='border-blue-100 border-2 shadow-lg p-5'>
                <h1 className="text-lg mb-2">My Account</h1>
                <hr></hr>
                <p className="mt-2">User Name: <b>{user?user.displayName:'NA'}</b></p>
                <p>Email: <b>{user?user.email:'NA'}</b></p>

                <button onClick={logout} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mt-4">LOGOUT</button>
            </div>
        </div>
    )
}

export default Home