import React from "react";
import PageHeader from "../../components/PageHeader";


export default () => {
    return (<div className="m-4">
        <PageHeader>
            <>
                <h4 className="text-lg">SKU List</h4>
                <button className="btn bg-blue-500">NEW</button>
            </>
        </PageHeader>
        <div>
            <form>
                <label>Product Name</label>
                <input type="text" maxLength={100} />
            </form>
        </div>
    </div>)
}