import React from "react"
import { Link } from "react-router-dom"
import logo from './../images/Logoblack.png'

const Home = () => {
    return (
        <div className='text-center pt-4' 
            style={{
                backgroundColor: '#4158D0',
                backgroundImage: 'linear-gradient(0deg, #FFDEE9 0%, #B5FFFC 100%)',
                height: '100vh'
            }} >
            <img src={logo} style={{maxWidth: 250, margin: '0 auto'}}  className="animate__animated animate__zoomInDown"/>
            {/*<h2 className="text-xl block text-white">LW3 Admin</h2>*/}
            <h4 className="my-4 text-teal-600 animate__animated animate__heartBeat animate__slower">Welcome to LW3 admin home.</h4>
            <Link to={'/auth'} className="p-4 bg-teal-800 mt-4 text-white rounded-md animate__animated animate__zoomInUp" style={{marginTop: 50, display: 'inline-block'}}>Login to dashboard</Link>
        </div>
    )
}

export default Home