import React, { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import { Link, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { callableFunction } from '../../firebase/index'
import { firestore as db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import {BiChevronUp, BiChevronDown, BiTrash, BiPlus} from 'react-icons/bi'
import { getAuth } from "firebase/auth";

export default () => {
    const [loading, setLoading] = useState(false)
    const [onIpfs, setonIpfs] = useState(true)
    //const [name, setName] = useState( '' )
    const [fields, setFields] = useState({
        name: '',
        fields: [{key: '', value: ''}]
    })
    //const [ifields, setIfields] = useState([ 0, 0, 0, 0, 0, 0, 0, 0, 0 ])
    //const [sfields, setSfields] = useState([ '', '', '', '', '', '', '', '', '', '' ])
//7636075699
//7636075699@gmail.com
    const location = useLocation()

    useEffect(() => {
        let params = new URLSearchParams(location.search)
        let pid = params.get('pid')

        if(pid) {
            getDoc(doc(db, "product_abstracts", pid)).then(resp => {
                let obj = resp.data()
                console.log(obj)
                //setName(obj.data.name)
                //setIfields(obj.data.ifields)
                //setSfields(obj.data.sfields)
            })
            .catch(err => {
                console.log(err)
            })
        }
        //console.log(params.get('pid'))
    }, [])

    function validate() {
        if(fields.name.trim().length < 1){
            return {isValid: false, message: 'Name is required'}
        }
        
        let isValid = true, message = ""
        fields.fields.forEach(field => {
            if(String(field.key).trim().length == 0 || String(field.value).trim().length == 0){
                isValid = false
                message = "All fields must have key value field!"
            }
        })

        return {
            isValid, message
        }
    }

    async function addProduct(event) {
        event.preventDefault()
        let validation = validate()
        if(validation.isValid){
            setLoading(true)

            let fireToken = await getAuth().currentUser.getIdToken()
            //console.log(name, ifields, sfields)
            callableFunction("addProductAbstractV2")({
                fields,
                onIpfs,
                fireToken
            }).then(result => {
                let data = result.data
                if(data.data.success){
                    toast.success("Product saved successfully")
                    setTimeout(() => {
                        window.location.href = "/products"
                    }, 1000);
                } else {
                    toast.error(data.data.message)
                }
                //console.log(data)
            })
            .catch(error => {
                const code = error.code;
                const message = error.message;
                const details = error.details;
                console.log(code, message, details)
                toast.error("Failed: "+error.message)
            }).finally(() => {
                setLoading(false)
            })

            //setLoading(false)
        } else {
            toast.error(validation.message)
        }
    }
    function addFieldKV(){
        setFields({
            name: fields.name,
            fields: fields.fields.concat({key:'', value: ''})
        })
    }

    function removeField(fi){
        if(fields.fields.length <= 1){
            return toast("At least one field is required!")
        }
        if(!window.confirm(`Remove field ${fi+1}?`)) return
        let temp = fields.fields.concat([])
        temp.splice(fi, 1)
        setFields({
            name: fields.name,
            fields: temp
        })
    }

    function moveField(fi, dir = 'up'){
        let temp = fields.fields.concat([])
        
        console.log(dir)

        if(dir === 'up'){
            if(fi == 0) return 
            let a = temp[fi]
            temp[fi] = temp[fi-1]
            temp[fi-1] = a
        } else {
            if(fi == temp.length-1) return 
            let a = temp[fi]
            temp[fi] = temp[fi+1]
            temp[fi+1] = a
            console.log("DONE", a)
        }

        setFields({
            name: fields.name,
            fields: temp
        })
    }


    function setFieldKeyValue(event, fi, type = "key"){
        let temp = fields.fields.concat([])
        temp[fi][type] = event.target.value
        setFields({
            name: fields.name,
            fields: temp
        })
        console.log(fields.fields)
    }

    return (<div className="m-4">
        <PageHeader>
            <>
                <h4 className="text-lg">Create New Product</h4>
                <Link to={"/products"}><button className="btn bg-blue-500">Back</button></Link>
            </>
        </PageHeader>
        <div>
            <form onSubmit={addProduct}>
                
                <div>
                    <label>Product Name*</label>
                    <input disabled={loading} type="text" placeholder="Minimum 3 characters" maxLength={100} defaultValue={fields.name} onChange={(e) => setFields({name: e.target.value, fields: fields.fields})}/>
                </div>
                
                {fields.fields.map((field, fi) => {
                    return (<div className="mt-2" key={fi+1}>
                    <div className="grid grid-cols-2 gap-4 py-2"><div>Field {fi+1}</div> <NewButtons length={fields.fields.length} index={fi} f1={() => removeField(fi)} f2={() => moveField(fi, 'up')}  f3={() => moveField(fi, 'down')}></NewButtons></div>
                    <div className="grid grid-cols-2 gap-4">
                        <div className="w-50 bg-white shadow ">
                            <input disabled={loading} type="text" step="any" maxLength={100} placeholder="Name" 
                                onChange={(e) => setFieldKeyValue(e, fi, 'key')}
                                value={field.key} />
                        </div>
                        <div className="w-50 bg-white shadow">
                        <input disabled={loading} type="text" step="any" maxLength={100} placeholder="Value" 
                            onChange={(e) => setFieldKeyValue(e, fi, 'value')}
                            value={field.value} />
                        </div>
                    </div>
                </div>)
                })}

            <div className="grid grid-cols-2 gap-4">
                <div className="mt-4 bg-white" style={{position: 'sticky', bottom: 0, paddingBottom: 10, paddingTop: 10 }}>
                    <button disabled={loading} type="button" className="btn bg-red-400" onClick={addFieldKV}><BiPlus style={{display:'inline'}}/>Add Field</button>
                </div>
                <div className="mt-4 bg-white text-right" style={{position: 'sticky', bottom: 0, paddingBottom: 10, paddingTop: 10 }}>
                    <button disabled={loading} className="btn bg-blue-400" onClick={addProduct}>{loading?"Creating product...":'Create Product'}</button>
                </div>
            </div>
                
            </form>
        </div>
    </div>)
}

function NewButtons({index = 0, length = 0, f1 = () => {}, f2= () => {}, f3= () => {}}){
    return (<div className="text-right">
    <button type="button" onClick={() => f1()} className="rounded-full w-8 h-8 text-red-500 border border-red-500 rounded p-2 hover:bg-red-100 hover:text-red-700 focus:outline-none focus:ring focus:ring-red-300"><BiTrash/></button>
    <button type="button" disabled={index==0} onClick={() => f2()} className={`${index==0?'cursor-not-allowed':''} rounded-full w-8 h-8 text-blue-500 border border-blue-500 rounded p-2 hover:bg-blue-100 hover:text-blue-700 focus:outline-none focus:ring focus:ring-blue-300 ml-2`}><BiChevronUp/></button>
    <button type="button" disabled={index==length-1} onClick={() => f3()} className={`${index==length-1?'cursor-not-allowed':''} rounded-full w-8 h-8 text-blue-500 border border-blue-500 rounded p-2 hover:bg-blue-100 hover:text-blue-700 focus:outline-none focus:ring focus:ring-blue-300 ml-2`}><BiChevronDown/></button>
    </div>)
}