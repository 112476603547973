import React from 'react';
import './index.css';
import App from './App';
import { createRoot } from "react-dom/client";
import { app } from './firebase';

// import dotenv from 'dotenv'
// dotenv.config()
// console.log(process.env)

createRoot(document.getElementById("root")).render(
  <App />
);
