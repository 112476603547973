import React, { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import { collection, getDocs, orderBy, query } from "firebase/firestore"; 
import { firestore, callableFunction } from "../../firebase";
import { Link } from "react-router-dom";
import QRCode from "react-qr-code";

export default () => {
  const [qrcodes, setQRCodes] = useState([])
  const [size, setSize] = useState(128)
  const [loading, setLoading] = useState(false)

  useEffect(() => {

    callableFunction("getProductAbstracts")().then(resp => {
      console.log(JSON.parse(resp.data))
    })
    .catch(err => {
      console.log(err)
    })
    .finally(() => {

    })
    setLoading(true)
    getDocs(query(collection(firestore, "products"), orderBy('name', 'asc')))
    .then(snapshot => {
      setQRCodes(snapshot.docs.map(doc => {
        return {...doc.data(), id: doc.id}
      }))
      console.log(snapshot.docs.map(doc => {
        return {...doc.data(), id: doc.id}
      }))
    })
    .catch((err) => {

    })
    .finally(() => {
      setLoading(false)
    })
  }, [])

  function onImageCownload(QRCodeElement, name, msize = 1) {
    const svg = document.getElementById(QRCodeElement);
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    setSize(256)

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode"+name;
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
      setSize(128)
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  function  getLink(prod){
    return prod.chipId && prod.chipId.length ? `https://verify.lw3.world/verify.html?id=${prod.chipId}` : `https://verify.lw3.world/web2/?pid=${prod.id}&bid=${prod.brandId}`
  }

  return (<div className="m-4">
    <PageHeader>
      <>
        <h4 className="text-lg">QR List</h4>
        <Link to={"/qr-codes/new"}><button className="btn bg-blue-500">NEW</button></Link>
      </>
    </PageHeader>
    <table className="w-full border-collapse border border-slate-400">
      <thead className='text-left my-20'>
        <tr>
          <th className="p-2">SN</th>
          <th className="p-2">ID</th>
          <th className="p-2">Name</th>
          <th className="p-2">Batch</th>
          <th className="p-2">QR Code</th>
          <th className="p-2">Timestamp</th>
          <th className="p-2">Action</th>
        </tr>
      </thead>
      <tbody>
        {!loading && qrcodes.length < 1 && <tr>
            <td colSpan="6" className="p-4 text-center">No QR Code generated!</td>
          </tr>}
        {loading && <tr>
            <td colSpan="6" className="p-4 text-center">Loading please wait...</td>
          </tr>}
        {qrcodes.map((prod, proi) =>(<tr key={proi} className="py-4">
          <td className="py-4 px-2">{proi+1}</td>
          <td className="py-4 px-2">{prod.id}</td>
          <td className="py-4 px-2">{prod.name}</td>
          <td className="py-4 px-2">{prod.batchId}</td>
          <td className="py-4 px-2">
              <QRCode
                  id={"QR"+proi}
                  size={size}
                  value={getLink(prod)}
                  viewBox={`0 0 ${size} ${size}`}
                />
          </td>
          <td className="py-4 px-2">{new Date(prod.ts.seconds*1000).toDateString()}</td>
          <td className="py-4 px-2">
            <a className="btn bg-red-500 mr-2" target="_blank" href={getLink(prod)}>Verify Link</a>
            <a className="btn bg-blue-500  mr-2" onClick={() => onImageCownload("QR"+proi, prod.name)}>Download</a>
            {/*<a className="btn bg-blue-500" onClick={() => onImageCownload("QR"+proi, prod.name, 2)}>Download (2x)</a>*/}
          </td>
        </tr>))}
      </tbody>
    </table>
  </div>)
}