import React, { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { firestore as db } from './../../firebase/index'
import { doc, getDoc, setDoc } from "firebase/firestore"; 
import { getAuth, onAuthStateChanged } from "firebase/auth";
const auth = getAuth()

export default ({isEdit = false}) => {
    const [loading, setLoading] = useState(false)
    const [admin, setAdmin] = useState(null)
    const [brand, setBrand] = useState( {
        name: '',
        tagline: '',
        instagram: '',
        twitter: '',
        facebook: '',
        youtube: '',
        website: ''
        
    })

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            setAdmin(user)
            console.log(user)
        })
        console.log("HELLo")
    }, [])

    useEffect(() => {
        if(admin) fetchBrand()
    }, [admin])
    

    function validate(brand) {
        if(!brand) {
            return {
                isValid: false
            }
        }
        if(brand.name.length < 1){
            return {isValid: false, message: 'Name is required'}
        }
        if(brand.website.length < 1){
            return {isValid: false, message: 'Website URL is required'}
        }

        ['twitter', 'facebook', 'youtube', 'instagram', 'website'].forEach(social => {
            if(brand[social] && !brand[social].startsWith('https:')){
                return {isValid: false, message: `Invalid ${social} url`}
            }
        })
        
        return {
            isValid: true
        }
    }

    function sanitizeData(data){
        let temp = {...data}
       Object.keys(temp).forEach(key => {
        temp[key] = temp[key].trim()
       })
        return data
    }

    async function saveBrand(event) {
        event.preventDefault()
        let data = sanitizeData(brand)
        console.log(data)
        let validation = validate(data)
        if(validation.isValid){
            setLoading(true)
            try {
                await setDoc(doc(db, "brands", admin.uid ) , data)
                toast.success("Brand saved successfully")
            } catch (error) {
                toast.error(error.message)
            } finally {
                setLoading(false)
            }
            //setLoading(false)
        } else {
            toast.error(validation.message)
        }
    }

    function  setField(key, value){
        setBrand({
            ...brand,
            [key]: value
        })
    }

    async function fetchBrand(){
        setLoading(true)
        try {
            let tokens = await getAuth().currentUser.getIdTokenResult()
            let resp = await getDoc(doc(db, 'brands', tokens.claims.bid))
            setBrand(resp.data())   
        } catch (error) {
            console.log(error.message)
        } finally {
            setLoading(false)
        }
    }

    return (<div className="m-4">
        <PageHeader>
            <>
                <h4 className="text-lg">My Brand</h4>
                <Link to={ isEdit? "/brand" : "/dashboard"}><button className="btn bg-blue-500">Back</button></Link>
            </>
        </PageHeader>

        <div>
            {isEdit? <form onSubmit={saveBrand}>

                <div>
                    <label>Brand Name*</label>
                    <input disabled={loading} type="text" placeholder="Minimum 3 characters" defaultValue={brand ? brand.name : ''} maxLength={100} onChange={(e) => setField('name', e.target.value)}/>
                </div>

                <div className="mt-2">
                    <label>Tagline*</label>
                    <input disabled={loading} type="text" placeholder="Minimum 3 characters" maxLength={100} defaultValue={brand ? brand.tagline : ''} onChange={(e) => setField('tagline', e.target.value)}/>
                </div>

                {['Website', 'Instagram', 'Facebook', 'Twitter', 'Youtube'].map(item => (
                    <div className="mt-2" key={item}>
                        <label>{item}</label>
                        <input disabled={loading} type="text" maxLength={200} defaultValue={brand ? brand[item.toLocaleLowerCase()] : ''} onChange={(e) => setField(item.toLocaleLowerCase(), e.target.value)}/>
                    </div>
                ))}

                <div className="mt-4 bg-white" style={{position: 'sticky', bottom: 0, paddingBottom: 10, paddingTop: 10 }}>
                    <button disabled={loading} className="btn bg-red-400" onClick={saveBrand}>{loading?"Saving brand details...":'Save Data'}</button>
                </div>
                </form> : <BrandShow brand={brand}/>}
        </div>

    </div>)
}

const BrandShow = ({brand}) => {

    return (<div>
            <div>
                <label>Brand Name*</label>
                <div className="bg-slate-50 p-2">{brand ? brand.name:''}</div>
            </div>

            <div className="mt-2">
                <label>Tagline*</label>
                <div className="bg-slate-50 p-2">{brand ? brand.tagline: ''}</div>
            </div>

            {['Website', 'Instagram', 'Facebook', 'Twitter', 'Youtube'].map(item => (
                <div className="mt-2" key={item}>
                    <label>{item}</label>
                    <div className="bg-slate-50 p-2">{brand ? (brand[item.toLowerCase()] || 'NA') : 'NA'}</div>
                </div>
            ))}

            <div className="mt-4 bg-white" style={{position: 'sticky', bottom: 0, paddingBottom: 10, paddingTop: 10 }}>
                <Link to={'/brand/edit'} className="btn bg-red-400">Update Brand</Link>
            </div>
    </div>)
}